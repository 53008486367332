// in templates.js
import * as React from "react";
import { Edit, Create, SimpleForm, TextInput, TabbedShowLayout, Tab, FileField, Filter, required, ImageInput, ImageField, useRefresh, useRedirect, NumberInput, TimeInput, BooleanInput } from "react-admin";
import BookIcon from "@material-ui/icons/Book";
import Grid from "@material-ui/core/Grid";
import { EditToolbar, EditActions } from "./Default";

export const SettingIcon = BookIcon;

const SettingFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);

export const SettingList = (props) => {
  const refresh = useRefresh();
  const redirect = useRedirect();

  redirect("/settings/1");
  // refresh();
  return null;
};

const SettingTitle = ({ record }) => {
  return <span>Setting</span>;
};

export const SettingEdit = (props) => {
  console.log("props", props);
  return (
    <div className="scroll-div">
      <Edit undoable={false} actions={<EditActions />} title={<SettingTitle />} {...props}>
        <SimpleForm toolbar={<EditToolbar />}>
          <Grid container spacing={1} style={{ width: "100%" }}>
            <TabbedShowLayout width="100%">
              <Tab label="ตั้งค่ายกเลิกใบเสนอราคา" width="100%">
                <BooleanInput label="เปิดใช้งาน" source="is_schedule_quotation_active" />
                <TextInput source="schedule_quotation_day" label="ระยะเวลา (วัน)" validate={required()} fullWidth type="number" />
                <li style={{ color: "gray", marginTop: -20, fontSize: 14 }}>หมายเหตุ : ระบบจะทำการตรวจสอบในเวลา 00.00 ของทุกวัน</li>
              </Tab>

              <Tab label="ตั้งค่ายกเลิกออเดอร์" width="100%">
                <BooleanInput label="เปิดใช้งาน" source="is_schedule_order_active" />
                <TextInput source="schedule_order_day" label="ระยะเวลา (วัน)" validate={required()} fullWidth type="number" />
                <li style={{ color: "gray", marginTop: -20, fontSize: 14 }}>หมายเหตุ : ระบบจะทำการตรวจสอบในเวลา 00.00 ของทุกวัน</li>
                <BooleanInput label="ยกเลิกใบเสนอราคาด้วย" source="is_cancel_quotation" />
              </Tab>

              <Tab label="ตั้งค่า Duplicate Item" width="100%">
                <BooleanInput label="เปิดใช้งาน" source="is_deplicate_check_active" />
                <TextInput source="deplicate_item_day" label="ระยะเวลา (วัน)" validate={required()} fullWidth type="number" />
              </Tab>
            </TabbedShowLayout>
          </Grid>
        </SimpleForm>
      </Edit>
    </div>
  );
};

export const SettingCreate = (props) => <div className="scroll-div"></div>;
